
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n/index";
import KTMenu from "@/layout/aside/Menu.vue";
import { asideTheme } from "@/core/helpers/config";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import IconNetworkStatus from "@/components/global/IconNetworkStatus.vue";
import store from "@/store";

export default defineComponent({
  name: "KTAside",
  components: {
    KTMenu,
    IconNetworkStatus,
  },
  props: {
    lightLogo: String,
    darkLogo: String,
    title: String,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();

    const hasBackofficeAccess = computed(() => {
      return store.getters.userHasFounderBackofficeAccess;
    });

    const hasPrescriberBackofficeAccess = computed(() => {
      return store.getters.userHasPrescriberBackofficeAccess;
    });

    const signOut = () => {
      const payload = { revoke_token: true };
      store
        .dispatch(Actions.LOGOUT, payload)
        .then(() => {
          router.push({ name: "sign-in" });
        })
        .catch(() => router.push({ name: "homepage" }));
    };

    const isOnline = computed(() => store.getters.isOnline);

    return {
      hasBackofficeAccess,
      hasPrescriberBackofficeAccess,
      asideTheme,
      signOut,
      isOnline,
      t,
    };
  },
});
