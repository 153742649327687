const DocMenuConfig = [
  {
    pages: [
      {
        heading: "Accueil",
        route: "/accueil",
        svgIcon: require("@/../public/media/icons/duotune/general/gen001.svg"),
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    heading: "Mes outils",
    route: "/route",
    pages: [
      {
        heading: "Mon compte",
        route: "/compte-utilisateur",
        svgIcon: require("@/../public/media/icons/duotune/communication/com013.svg"),
        fontIcon: "bi-layers",
      },
      {
        sectionTitle: "Exploitations",
        svgIcon: require("@/../public/media/icons/duotune/layouts/lay001.svg"),
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Mes exploitations",
            route: "/exploitations",
          },
          {
            heading: "Nouvelle exploitation",
            route: "/nouvelle-exploitation",
          },
        ],
      },
      {
        sectionTitle: "Diagnostics",
        svgIcon: require("@/../public/media/icons/duotune/general/gen004.svg"),
        fontIcon: "bi-archive",
        sub: [
          {
            heading: "Mes diagnostics",
            route: "/diagnostics",
          },
          {
            heading: "Nouveau diagnostic",
            route: "/nouveau-diagnostic",
          },
        ],
      },
      {
        sectionTitle: "Services écosystémiques",
        svgIcon: require("@/../public/media/icons/duotune/technology/teh010.svg"),
        fontIcon: "bi-archive",
        sub: [
          {
            heading: "Liste des services",
            route: "/liste-services-ecosystemiques",
          },
          {
            heading: "Méthode de calcul",
            route: "/methode-calcul-services-ecosystemiques",
          },
        ],
      },
    ],
  },
  {
    heading: "Mes informations",
    route: "/route",
    pages: [
      {
        heading: "Mes notifications",
        route: "/notifications",
        svgIcon: require("@/../public/media/icons/duotune/general/gen007.svg"),
        fontIcon: "bi-shield-check",
      },
      {
        heading: "Mes projets",
        route: "/mes-projets",
        svgIcon: require("@/../public/media/icons/duotune/communication/com008.svg"),
        fontIcon: "bi-shield-check",
      },
    ],
  },
  {
    heading: "AgriBEST en détails",
    route: "/apps",
    pages: [
      {
        heading: "Qu'est ce que c'est ?",
        route: "/agribest",
        svgIcon: require("@/../public/media/icons/duotune/general/gen045.svg"),
        fontIcon: "bi-printer",
      },
      {
        heading: "Aller plus loin",
        route: "/aller-plus-loin",
        svgIcon: require("@/../public/media/icons/duotune/general/gen046.svg"),
        fontIcon: "bi-calendar3-event",
      },
      {
        heading: "Partenaires",
        route: "/partenaires",
        svgIcon: require("@/../public/media/icons/duotune/communication/com014.svg"),
        fontIcon: "bi-chat-left",
      },
      {
        heading: "Contact",
        route: "/contact",
        svgIcon: require("@/../public/media/icons/duotune/communication/com011.svg"),
        fontIcon: "bi-chat-left",
      },
    ],
  },
  {
    heading: "Autres",
    route: "/apps",
    pages: [
      {
        heading: "Mentions légales",
        route: "/mentions-legales",
        svgIcon: require("@/../public/media/icons/duotune/finance/fin001.svg"),
        fontIcon: "bi-printer",
      },
      {
        heading: "Conditions d'utilisation",
        route: "/conditions-utilisation",
        svgIcon: require("@/../public/media/icons/duotune/files/fil008.svg"),
        fontIcon: "bi-cart",
      },
      {
        heading: "Protection des données",
        route: "/charte-protection-donnees",
        svgIcon: require("@/../public/media/icons/duotune/general/gen048.svg"),
        fontIcon: "bi-calendar3-event",
      },
      {
        heading: "Politique de cookies",
        route: "/politique-cookies",
        svgIcon: require("@/../public/media/icons/duotune/communication/com009.svg"),
        fontIcon: "bi-calendar3-event",
      },
    ],
  },
];

export default DocMenuConfig;
