
import { computed, defineComponent, onMounted, onUnmounted } from "vue";
import { notify } from "@/core/helpers/globalJaya";
import OfflineService from "@/core/services/OfflineService";
import { Mutations } from "@/store/enums/StoreEnums";
import store from "@/store";

export default defineComponent({
  name: "IconNetworkStatus",
  setup() {
    const isOnline = computed(() => store.getters.isOnline);

    return {
      isOnline,
    };
  },
});
