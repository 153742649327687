
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "topbar",
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    const notificationCount = computed(
      () => store.getters.getNotificationCount
    );
    const getIconClass = computed(() =>
      router.currentRoute.value.name === "notifications"
        ? "svg-icon-primary"
        : ""
    );
    const getBellIcon = computed(() =>
      router.currentRoute.value.name === "notifications"
        ? require("@/../public/media/icons/duotune/general/gen007rot.svg")
        : require("@/../public/media/icons/duotune/general/gen007.svg")
    );
    const isOnline = computed(() => store.getters.isOnline);
    return { notificationCount, getIconClass, getBellIcon, isOnline };
  },
});
