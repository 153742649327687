import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  id: "kt_header",
  style: {},
  class: "header align-items-stretch"
}
const _hoisted_2 = { class: "d-flex align-items-center d-lg-none ms-3 me-1" }
const _hoisted_3 = {
  class: "btn btn-icon w-30px h-30px w-md-40px h-md-40px",
  id: "kt_aside_mobile_toggle",
  title: "Voir le menu"
}
const _hoisted_4 = { class: "svg-icon svg-icon-1 mt-1" }
const _hoisted_5 = { class: "svg-icon svg-icon-1 mt-1" }
const _hoisted_6 = { class: "d-flex flex-column align-items-center justify-content-center fs-1 text-center" }
const _hoisted_7 = { class: "d-flex align-items-stretch" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_KTTopbar = _resolveComponent("KTTopbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([{
        'container-fluid': _ctx.headerWidthFluid,
        'container-xxl': !_ctx.headerWidthFluid,
      }, "d-flex align-items-stretch justify-content-between"])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_inline_svg, {
              src: 
                require('@/../public/media/icons/duotune/abstract/abs015.svg')
              
            }, null, 8, ["src"])
          ])
        ]),
        (_ctx.router.currentRoute.value.meta.displayPrev)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "btn btn-icon w-30px h-30px w-md-40px h-md-40px",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.router.back && _ctx.router.back(...args))),
              title: "Retour"
            }, [
              _createElementVNode("span", _hoisted_5, [
                _createVNode(_component_inline_svg, {
                  src: 
                require('@/../public/media/icons/duotune/arrows/arr063.svg')
              
                }, null, 8, ["src"])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_KTTopbar)
      ])
    ], 2)
  ]))
}