import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createTextVNode(_toDisplayString(_ctx.isOnline ? "En-ligne" : " Hors-ligne") + " ", 1),
    _createElementVNode("span", {
      id: "icon-network-status",
      class: _normalizeClass(["svg-icon svg-icon-1", _ctx.isOnline ? 'svg-icon-primary' : ' svg-icon-danger'])
    }, [
      _createVNode(_component_inline_svg, {
        src: require('@/../public/media/icons/duotune/communication/com001.svg')
      }, null, 8, ["src"])
    ], 2)
  ], 64))
}